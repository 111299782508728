body {
  background-color: #fff;
  color: #000;
}

body.dark-mode {
  background-color: #121212;
  /* color: #d1d1d1; */
  color: #bbbbbb;
}

body.dark-mode textarea {
  background-color: #121212;
  color: #868686;
}

body.dark-mode textarea::placeholder {
  color: #2b2b2b; /* Dark grey */
}

body.dark-mode input {
  background-color: #1e1e1e; /* adjust this value to your desired shade of dark grey */
  color: #868686; /* you might want to adjust the text color to be readable on the new background */
}
input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}
body.dark-mode textarea {
  outline-color: #2b2b2b;
  /* outline-style: solid;
  outline-width: 8px; */
}

/* a, a:visited { */
header a,
header a:visited {
  color: #000 !important;
  text-decoration: none;
}

.highlighted {
  background-color: #fdffb6;
}
body.dark-mode .highlighted {
  background-color: #4d4c22;
}

a,
a:visited {
  color: #000;
  text-decoration: none;
}

body.dark-mode a,
body.dark-mode a:visited {
  color: #fff;
  text-decoration: none;
}

.markdown-content a {
  text-decoration: underline;
}

.item-tag-manager {
  width: 500px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #fff;
}

.brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
}

nav {
  display: flex;
}

nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav a {
  display: block;
  padding: 0.5rem;
  /* color: #fff; */
  text-decoration: none;
}

button {
  /* background-color: #ffb900; */
  /* background-color: #7fc9b0; */
  color: #222;
  border: 0;
  padding: 0.2rem 0.5rem;
  border-radius: 1px;
  cursor: pointer;
  /* font-weight: bold; */
  /* text-transform: uppercase; */
}

body.dark-mode button {
  /* background-color: #2b2b2b; Dark grey to match the textarea */
  background-color: #222;
  /* color: #868686; */
  color: #c1c1c1;
}

body.dark-mode button.selected {
  background-color: #afafaf; /* Dark grey to match the textarea */
  color: #1a1a1a;
}

/* i dont think this is doing anything */
/* button.dark-mode {
  border: none;
  padding: 4px 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: background-color 0.3s;
} */

/* button.dark-mode.selected {
  background-color: green;
  color: #fff;
} */

main {
  padding: 1rem;
}

.feedback-type-span {
  width: 150px;
  display: inline-block;
}
.feedback-button,
.feedback-button:hover {
  background-color: #c7d3d5;
  border-style: none !important;
}

.feedback-button-bold,
.feedback-button-bold:hover {
  background-color: #8ca6a5;
}

body.dark-mode .feedback-button {
  border-style: none !important;
  background-color: #2b2b2b !important;
}

body.dark-mode .feedback-button-bold {
  background-color: #6a6a6a !important;
}

.unsaved {
  background-color: green;
}

.saved {
  background-color: #888888;
}

.circle-button-inner {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin: 7px;
  flex-shrink: 0;
}

.circle-button-outer {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin: 6px;
  flex-shrink: 0;
  /* comment this in to see the outer circle  */
  /* background-color: #bbb; */
}

.item-view-button-area {
  /* margin-top: 16px; */
  margin-top: 7px;
  margin-left: 5px;
  margin-bottom: 3px;
}

.loading {
  background-color: orange;
}

.is-public {
  background-color: #caffbf;
  /* background-color: #fbff80; */
}

.document-editor {
  width: 100%;
  /* min-height: 200px; */
  padding: 20px;
  border: 0px solid #ccc;
  border-radius: 0px;
  outline: none;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  resize: vertical; /* Allow vertical resizing, disallow horizontal resizing */
}

.flex-container {
  display: flex;
  align-items: center;
}

.mode-toggle-button {
  margin-left: 10px;
}

.document-editor::placeholder {
  color: lightgrey;
}

.visibility-switch {
  padding: 10px;
  display: flex;
  overflow: hidden;
  width: 300px;
  height: 52px;
}

.visibility-switch label {
  flex: 1;
  width: 10px;
  padding: 4px;
  text-align: center;
  background-color: #2b2b2b; /* Dark grey to match the textarea */
  color: #868686;
  cursor: pointer;
  transition: background 0.3s;
}

.visibility-switch label.active {
  background-color: #6b6b6b; /* Dark grey to match the textarea */
  color: #262626;
}

.summary-divider {
  color: #2b2b2b; /* Dark grey to match the textarea */
}

.visibility-switch input {
  display: none;
}

/* a.child-item-link {
  text-decoration: underline;
  border: #000;
  margin: 10px;
  text-decoration-color: #0000FF;
  color: #0000FF;
} */

.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #e9ecef; /* This sets the background color to a light grey */
}

/* If you also want to change the background color of the checkbox */
.form-checkbox {
  margin-right: 5px;
  background-color: #e9ecef;
}

.form-textarea {
  height: 100px;
  resize: vertical;
}

.form-checkbox-label {
  display: flex;
  align-items: center;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.form-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.form-button:hover {
  background-color: #0056b3;
}

.item-view-button {
  margin-top: 4px;
  margin-right: 4px;
}

.square-feedback-button {
  margin: 7px;
  width: 25px;
  height: 25px;
}

.small-square-feedback-button {
  margin: 5px;
  width: 15px;
  height: 15px;
}

.control-panel-button-rectangular {
  margin: 5px;
}

.text-input {
  white-space: pre-wrap; /* Ensures that whitespace is preserved and lines wrap */
  overflow-wrap: break-word; /* Optional: Ensures long words do not cause horizontal scrolling */
  resize: none; /* Optional: Prevents resizing the textarea */
}

.small-text-input {
  width: 250px;
}

.large-text-input {
  width: 450px;
  max-width: 450px;
  width: 100%;
  height: 260px;
}

.tag-item {
  background-color: #1f1f1f;
}

.item-with-kids {
  background-color: #825555;
}

.sidebar {
  position: fixed;
  left: -100%; /* Start off-screen */
  top: 0;
  width: 80%; /* 80% of the screen width */
  height: 100%;
  /* background-color: #ffffff; */
  background-color: #282828;
  transition: left 0.3s; /* Smooth transition for sliding effect */
  overflow-y: scroll;
  z-index: 1000;
}

.sidebar.open {
  left: 0; /* Slide in */
}

.item-create-modal {
  margin-top: 16px;
}
.item-create-modal {
  display: flex;
}

.item-create-modal .modal {
  position: absolute;
  left: 100%; /* Positions the modal to the right of the container */
  top: 0;
  background-color: white; /* Background color of the modal */
  border: 1px solid #ddd; /* Border for the modal */
  padding: 10px; /* Padding inside the modal */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Adds shadow to the modal */
  z-index: 100; /* Ensures the modal is above other elements */
}

.item-create-modal .modal ul li {
  margin-bottom: 10px; /* Adds space below each list item */
}

.item-create-modal .modal ul li button {
  display: block; /* Makes the button take the full width of the list item */
  width: 100%; /* Optional: Ensures the button stretches to fill the list item */
  /* Add any additional styling you want for the buttons here */
}

.input-textbox-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns children to the start (top) of the container */
}

.dropdown {
  text-align: center;
  border: none;
  /* Other styles for your dropdown */
}

/* Style for the dropdown in dark mode */
body.dark-mode .dropdown {
  background-color: #222; /* Dark background to match dark mode buttons */
  color: #c1c1c1; /* Light text color for visibility */
  /* border: 1px solid #2b2b2b; */
  padding: 0.2rem 0.5rem; /* Padding similar to the buttons */
  cursor: pointer; /* Cursor pointer to indicate it's clickable */
  /* width: 100%; */
  /* width: 80px; */
  width: auto;
}

/* Style for options in the dropdown */
body.dark-mode .dropdown option {
  background-color: #121212; /* Dark background for options */
  color: #c1c1c1; /* Light text color for options */
}

/* Focus styles for accessibility */
body.dark-mode .dropdown:focus {
  outline: none;
  border-color: #afafaf; /* Slightly lighter border for focus indication */
}

/* Additional styles to adjust the dropdown arrow, padding, etc., if needed */

.dropdown {
  -webkit-appearance: none; /* for Chrome, Safari, and Opera */
  -moz-appearance: none; /* for Firefox */
  appearance: none; /* Standard syntax */
}

body.dark-mode .dropdown option {
  background-color: #222; /* Dark background for options */
  color: #c1c1c1; /* Light text color for options */
  padding: 0.2rem 0.5rem; /* Padding for options */
  /* Add other styles for options */
}

.item-create-modal-button {
  margin-bottom: 2px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
